import React, { useState } from 'react';
import customStyle from "../../../../Assets/CSS/stylesheet_UHS";
import axios from "axios";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, CircularProgress, Fab, TextField, withStyles } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: "absolute",
        height: "290px",
        width: "795px",
        background: "#7878bb47",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100
    },
}));

const AutoLoginLink = ({ onClick }) => {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [url, setUrl] = useState('');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [messageColor, setMessageColor] = useState("#27AE60");
    const [isRegistered, setIsRegistered] = useState(true);

    const setUserValue = (value) => {
        setIsRegistered(true);
        setSuccessMessage("");
        setEmail(value.trim());
        if (value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setEmailError(false);
            setIsEnabled(true);
        } else {
            setEmailError(true);
            setIsEnabled(false);
        }
    }

    const generateLink = () => {
        resetFields();
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('bearerToken')
            }
        }
        const obj = {
            "user": email,
            "cognitoUserPool": process.env.REACT_APP_enrollment_user_pool,
        };
        axios.get(`${process.env.REACT_APP_enrollmentBaseUrl}/setupfamily/getMemberByEmail/${email}`)
            .then(response => {
                if (response.data.response) {
                    let data = JSON.parse(JSON.stringify(response.data.response));
                    if (!data.enrollFlag || (data.enrollFlag && data.reenrollmentFlag)) {
                        axios.post(`${process.env.REACT_APP_csrBaseUrl}memberportal/resetPassword`, obj, config)
                            .then(res => {
                                if (res && res.data) {
                                    setUrl(`${process.env.REACT_APP_enrollment_url}?username=${email}&password=${res.data.password}`);
                                } else {
                                    setLoading(false);
                                    setSuccessMessage("The prospect has not yet created an account. Resend the auth link so the prospect can sign up.");
                                    setMessageColor("#ff0000");
                                    setIsRegistered(false);
                                }
                                setLoading(false);
                            })
                            .catch((error) => {
                                if (error.response.status === 500) {
                                    setLoading(false);
                                    setSuccessMessage("The prospect has not yet created an account. Resend the auth link so the prospect can sign up.");
                                    setMessageColor("#ff0000");
                                    setIsRegistered(false);
                                }
                            });
                    } else {
                        setLoading(false);
                        setSuccessMessage("User is already enrolled successfully!");
                        setMessageColor("#ff0000");
                    }
                } else {
                    setLoading(false);
                    setSuccessMessage("No member with this email exists.");
                    setMessageColor("#ff0000");
                }
            })
    };

    const sendAuthLink = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_enrollmentBaseUrl}/setupfamily/getMemberByEmail/${email}`)
            .then(response => {
                if (response.data.response) {
                    let data = JSON.parse(JSON.stringify(response.data.response));
                    let countryCode = "+1";
                    let phone = "";
                    if (data.phone.length === 13) phone = data.phone.substr(3);
                    else phone = data.phone.substr(2);
                    if (Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta") countryCode = "+91";
                    let newPhone = countryCode + phone;
                    if (!data.enrollFlag || (data.enrollFlag && data.reenrollmentFlag)) {
                        axios.get(`${process.env.REACT_APP_enrollmentBaseUrl}/enrollment/registration/${email}/${newPhone}/all`)
                            .then((response) => {
                                if (response && response.data.code === 200 || response.data.code === 202) {
                                    setSuccessMessage("Authorization SMS & mail sent successfully!");
                                    setMessageColor("#27AE60");
                                } else if (response.data.code === 204) {
                                    setSuccessMessage("Authorization SMS & mail was RESENT!");
                                    setMessageColor("#27AE60");
                                } else if (response.data.code === 409) {
                                    setSuccessMessage("User already exists in the given User Pool.");
                                    setMessageColor("#27AE60");
                                } else if (response.data.code === 500) {
                                    setSuccessMessage("Oops! Something's not right. If you're still having trouble, call us on (800) 921-4505");
                                    setMessageColor("#ff0000");
                                }
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                if (error.response.status === 500) {
                                    setLoading(false);
                                    setSuccessMessage("Oops! Something's not right. If you're still having trouble, call us on (800) 921-4505");
                                    setMessageColor("#ff0000");
                                }
                            });
                    } else {
                        setLoading(false);
                        setSuccessMessage("User is already enrolled successfully!");
                        setMessageColor("#ff0000");
                    }
                } else {
                    setLoading(false);
                    setSuccessMessage("No member with this email exists.");
                    setMessageColor("#ff0000");
                }
            });
    }

    const sendLink = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_enrollmentBaseUrl}/setupfamily/getMemberByEmail/${email}`)
            .then(response => {
                if (response.data.response) {
                    let data = JSON.parse(JSON.stringify(response.data.response));
                    let phone;
                    if (data.phone.length === 13) {
                        phone = data.phone.substr(3);
                    } else {
                        phone = data.phone.substr(2);
                    }
                    let payload = {
                        email: email,
                        phone: phone,
                        autoLoginLink: url
                    }
                    if (!data.enrollFlag || (data.enrollFlag && data.reenrollmentFlag)) {
                        axios.post(`${process.env.REACT_APP_enrollmentBaseUrl}/enrollment/send-auto-login`, payload)
                            .then((response) => {
                                if (response && response.data.code === 200 || response.data.code === 202) {
                                    setSuccessMessage("Autologin link sent successfully!");
                                    setMessageColor("#27AE60");
                                } else if (response.data.code === 500) {
                                    setSuccessMessage(response.data.message);
                                    setMessageColor("#ff0000");
                                }
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                if (error.response.status === 500) {
                                    setLoading(false);
                                    setSuccessMessage("Oops! Something's not right. If you're still having trouble, call us on (800) 921-4505");
                                    setMessageColor("#ff0000");
                                }
                            });
                    } else {
                        setLoading(false);
                        setSuccessMessage("User is already enrolled successfully!");
                        setMessageColor("#ff0000");
                    }
                } else {
                    setLoading(false);
                    setSuccessMessage("No member with this email exists.");
                    setMessageColor("#ff0000");
                }
            });
    }

    const resetFields = () => {
        setLoading(true);
        setSuccessMessage("");
        setUrl("");
        setCopied(false);
    }

    const refreshClick = () => {
        setEmail("");
        setIsEnabled(false);
        resetFields();
        setLoading(false);
        setIsRegistered(true);
    }

    const copyClick = () => {
        setCopied(true);
        setSuccessMessage("Link copied to clipboard");
        setMessageColor("#27AE60");
    }

    return (
        <div style={{ height: '300px' }}>
            <div
                className={classes.loader}
                style={{
                    display: loading ? "flex" : "none",
                }}
            >
                <CircularProgress />
            </div>
            <div style={{ padding: '2%', width: '830px', marginTop: '0%' }}>
                <div style={customStyle.HomeContainer}>
                    <div style={customStyle.HomeMAinChild}>

                        <div style={customStyle.HomeTextContiner}>
                            <div style={{ width: '440px', margin: '10px', display: "flex" }}>
                                <TextField onChange={(e) => setUserValue(e.target.value)} required={true} name={'email'} label={'Email ID'} value={email} disabled={false} style={customStyle.textFieldWrpAgent} length={50} type={'email'} helperText={emailError && 'Enter a valid email ID'} error={emailError} helperMsg={'Email Id required'}></TextField>
                                <Fab style={{ ...customStyle.buttonStyle, width: '40px', backgroundColor: email ? "#1976d2" : "" }} onClick={() => refreshClick()}><RefreshIcon /></Fab>
                            </div>
                        </div>
                        <div>
                            <b style={{ color: messageColor }}>{successMessage}</b>
                        </div>

                        <div style={customStyle.urngenBtnwrp}>
                            {
                                isRegistered ?
                                    <div style={{ margin: '10px' }}>
                                        <Button variant="contained" color="primary" onClick={generateLink} disabled={!isEnabled}>Generate Link</Button>
                                    </div>
                                    :
                                    <div style={{ margin: '10px' }}>
                                        <Button variant="contained" color="primary" onClick={sendAuthLink} disabled={!isEnabled}>Send Auth Link</Button>
                                    </div>
                            }
                        </div>
                        {
                            url &&
                            <div style={customStyle.urngenBtnwrp}>
                                <div style={customStyle.HomeLoginUrlWrp}>
                                    <div>{url}</div>
                                    <CopyToClipboard text={url} onCopy={() => copyClick()}>
                                        <Fab style={{ ...customStyle.buttonStyle, backgroundColor: copied ? "#27AE60" : "#1976d2" }}><ContentCopyIcon /></Fab>
                                    </CopyToClipboard>
                                    <Fab onClick={sendLink} style={customStyle.buttonStyle}><SendIcon /></Fab>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutoLoginLink;
